import React from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout/layout'
import styled from '@emotion/styled'
import { rhythm } from '../utils/typography'
import { css } from '@emotion/core'
import FlexContainer from '../components/layout/flex-container'

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const Width50 = styled.div`
    margin: ${rhythm(1)} 0;
    width: 50%;
    text-align: center;
  `
  const leftParagraphContainerStyle = css`
    padding: ${rhythm(1)};
    background: var(--colors-odd-panel-bg);
    border-radius: 10px;
    box-shadow: 1px 2px 3px var(--colors-panel-shadow);
  `
  const leftParagraphContainerEvenStyle = css`
    padding: ${rhythm(1)};
    background: var(--colors-even-panel-bg);
    border-radius: 10px;
    box-shadow: 1px 2px 3px var(--colors-panel-shadow);
  `

  const items = [
    {
      text:
        'If you are interested in - or just curious about - what I think, what I know or any other story that I might tell... Well just visit the blog!',
      link: '/blog/',
      linkTitle: 'MY BLOG',
    },
    {
      text:
        'If you want to know who I am and why I decided to create this "place", you could read my bio.',
      link: '/bio/',
      linkTitle: 'MY BIO',
    },
    {
      text:
        "What I did and still do for a living? That's in my technical profile and there's a lot more!",
      link: '/profile/',
      linkTitle: 'MY TECH PROFILE',
    },
  ]

  return (
    <Layout location={location} title={siteTitle} subTitle="Web developer">
      <SEO title="Home" />
      <div
        css={css`
          text-align: center;
        `}
      >
        {' '}
        <h4
          css={css`
            font-size: ${rhythm(1)};
          `}
        >
          Hi! Welcome to my place{' '}
          <span role="img" aria-label="smile">
            😊
          </span>
        </h4>
        <p
          css={css`
            font-size: ${rhythm(1)};
          `}
        >
          I am an italian{' '}
          <span role="img" aria-label="spaghetti">
            🍝
          </span>{' '}
          full stack web developer{' '}
          <span role="img" aria-label="laptop">
            💻
          </span>
          , a husband{' '}
          <span role="img" aria-label="couple">
            👩‍❤️‍👨
          </span>
          , a father{' '}
          <span role="img" aria-label="family">
            👨‍👩‍👧‍👦
          </span>{' '}
          and a cat owner{' '}
          <span role="img" aria-label="cat">
            🐈
          </span>
          .
        </p>
      </div>
      {items.map((d, index) => (
        <FlexContainer
          css={css`
            align-items: center;
          `}
          key={d.link}
        >
          <Width50>
            <div
              css={
                index % 2
                  ? leftParagraphContainerStyle
                  : leftParagraphContainerEvenStyle
              }
            >
              <p>{d.text}</p>
            </div>
          </Width50>
          <Width50>
            <strong>
              {' '}
              <Link to={d.link}>{d.linkTitle}</Link>
            </strong>
          </Width50>
        </FlexContainer>
      ))}
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
